import { useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import PlayersPanel from 'components/players_panel/PlayersPanel'
import TeamFixturesPanel from 'components/teams_fixtures_panel/TeamsFixturesPanel'

export default function PlayersTeamsPanel () {
  const [value, setValue] = useState('players')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid grey',
        mt: theme => theme.spacing(2),
        mb: theme => theme.spacing(2),
        ml: theme => {
          return {
            xs: theme.spacing(2),
            sm: theme.spacing(2),
            md: theme.spacing(1)
          }
        },
        mr: theme => {
          return {
            xs: theme.spacing(2),
            sm: theme.spacing(2),
            md: theme.spacing(2)
          }
        },
        borderRadius: '0.5em',
        height: { xs: '800px', sm: '800px', md: '800px' },

        overflowY: 'auto',
        alignItems: 'center',
        typography: 'body1',
        flexGrow: {
          xs: 1, // Allow to grow for small screens
          sm: 1, // Allow to grow for medium screens
          md: 0.2, // Use less space for larger screens
          lg: 0.2,
          xl: 0.2
        },
        width: {
          xs: '100%', // Full width for smaller screens
          sm: '100%', // Full width for medium screens
          md: '90%' // Auto width for larger screens
          // xl: 'auto'
        }
        // flex: 1
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: theme => theme.spacing(1),
            alignItems: 'center'
          }}
        >
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab label='Players' value='players' />
            <Tab label='Teams' value='teams' />
          </TabList>
        </Box>
        <TabPanel
          sx={{
            padding: theme => theme.spacing(1),
            width: '100%'
          }}
          value='players'
        >
          <PlayersPanel />
        </TabPanel>

        <TabPanel
          sx={{ padding: theme => theme.spacing(1), width: '100%' }}
          value='teams'
        >
          <TeamFixturesPanel />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
