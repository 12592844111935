import React, { useEffect, useState } from 'react'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import FormControl from '@mui/material/FormControl'
import grey from '@mui/material/colors/grey'

export default function TeamSelector () {
  const { gameweekInfo } = useAppSharedState()
  const { setTeamsSelectedForPlayersTable, teamsSelectedForPlayersTable } =
    useDraftsSharedState()

  const [teamNames, setTeamNames] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])

  useEffect(() => {
    // Extract the team short names from gameweekInfo
    if (!gameweekInfo || !gameweekInfo.teams) return
    const teams = gameweekInfo.teams.map(team => team.short_name)
    setTeamNames(teams)
    setTeamsSelectedForPlayersTable([]) // Initially no filter, show all players
  }, [gameweekInfo, setTeamsSelectedForPlayersTable])

  const handleTeamSelectionChange = event => {
    const value = event.target.value

    // If no teams are selected, it will mean all teams are included
    if (value.length === 0) {
      setTeamsSelectedForPlayersTable([]) // Show all players
    } else {
      setTeamsSelectedForPlayersTable(value) // Apply filter for selected teams
    }

    setSelectedTeams(value) // Update the selected teams in the state
  }

  useEffect(() => {
    // Update the selected teams in the state
    setSelectedTeams(teamsSelectedForPlayersTable)
  }, [teamsSelectedForPlayersTable])
  return (
    <FormControl
      size='small'
      variant='filled'
      sx={{
        margin: theme => theme.spacing(0.5),
        width: { xs: '100px', sm: '140px', md: '140px' },
        backgroundColor: grey[900],
        border: '1px solid grey',
        borderRadius: '0.2em',
        fontWeight: '400'
      }}
    >
      <InputLabel size='small' shrink={true}>
        Team
      </InputLabel>
      <Select
        size='small'
        labelId='demo-multiple-checkbox-label'
        multiple
        value={selectedTeams}
        onChange={handleTeamSelectionChange}
        renderValue={selected =>
          selected.length === 0 ? '' : selected.join(', ')
        } // Show "All Teams" if none selected
        sx={{
          width: '100%',
          fontSize: { xs: '10px', sm: '14px', md: '16px' },
          backgroundColor: grey[800],
          color: 'white'
        }}
      >
        {/* Render the list of teams */}
        {teamNames.map(team => (
          <MenuItem
            key={team}
            value={team}
            sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
          >
            <Checkbox size='small' checked={selectedTeams.includes(team)} />
            <ListItemText
              primary={team}
              sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
