// DraftsSharedContext.js
import { createContext, useContext, useState, useEffect } from 'react'
import { useAppSharedState } from 'state/AppSharedContext'
import axiosService from 'utils/axios'
import CONFIG from 'config.json'
import { ROUTES } from 'utils/globalSettings'

const SharedStateContext = createContext()

export const DraftsSharedStateProvider = ({ children }) => {
  const { gameweekInfo, fplTeamId } = useAppSharedState()
  const [draftsList, setDraftsList] = useState([])
  const [playerInfoRadioValue, setPlayerInfoRadioValue] = useState(
    CONFIG['defaultPlayerInfoRadioValue']
  )
  const [priceSliderValue, setPriceSliderValue] = useState([null, null])
  const [appliedPriceSliderValue, setAppliedPriceSliderValue] = useState([
    null,
    null
  ])
  const [playersTableData, setPlayersTableData] = useState([])
  const [teamsTableData, setTeamsTableData] = useState([])
  const [teamsTableColumns, setTeamsTableColumns] = useState([])
  const [numFixturesTeamsTable, setNumFixturesTeamsTable] = useState(
    CONFIG['defaultNumFixtures']
  )
  const [highlightedPlayerIds, setHighlightedPlayerIds] = useState([])
  const [playerSubstituteCandidateId, setPlayerSubstituteCandidateId] =
    useState(null)
  const [selectedDraftCaptainPositionId, setSelectedDraftCaptainPositionId] =
    useState(null)
  const [
    selectedDraftViceCaptainPositionId,
    setSelectedDraftViceCaptainPositionId
  ] = useState(null)
  const [selectedDraftId, setSelectedDraftId] = useState(null)
  const [selectedDraft, setSelectedDraft] = useState({})
  const [positionIdToTransfer, setPositionIdToTransfer] = useState(null)
  const [transferMode, setTransferMode] = useState(false)
  const [transferModeEmptyTeam, setTransferModeEmptyTeam] = useState(false)
  const [teamsSelectedForPlayersTable, setTeamsSelectedForPlayersTable] =
    useState([])
  const [
    positionsSelectedForPlayersTable,
    setPositionsSelectedForPlayersTable
  ] = useState([])
  const [playersTableApplyTrigger, setPlayersTableApplyTrigger] =
    useState(false)

  useEffect(() => {
    setPriceSliderValue([
      gameweekInfo.min_player_price,
      gameweekInfo.max_player_price
    ])
    setAppliedPriceSliderValue(priceSliderValue)
  }, [gameweekInfo])

  useEffect(() => {
    if (fplTeamId === null) return

    if (draftsList.length > 0) return

    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)

      if (response.data.length > 0) {
        setSelectedDraftId(response.data[0].id)
      }
    })
  }, [fplTeamId])

  useEffect(() => {
    if (draftsList.length === 0) {
      setSelectedDraft({})
      setSelectedDraftCaptainPositionId(null)
      setSelectedDraftViceCaptainPositionId(null)
      return
    }
    if (selectedDraftId === undefined) return
    if (selectedDraftId === null) return

    const draftTeam = draftsList.find(draft => draft.id === selectedDraftId)
    if (draftTeam === undefined) {
      setSelectedDraft({})
      setSelectedDraftCaptainPositionId(null)
      setSelectedDraftViceCaptainPositionId(null)
      return
    }
    setSelectedDraft(draftTeam)
    setSelectedDraftCaptainPositionId(draftTeam.captain_position_id)
    setSelectedDraftViceCaptainPositionId(draftTeam.vice_captain_position_id)
  }, [selectedDraftId, draftsList])

  return (
    <SharedStateContext.Provider
      value={{
        playerInfoRadioValue,
        setPlayerInfoRadioValue,
        priceSliderValue,
        setPriceSliderValue,
        playersTableData,
        setPlayersTableData,
        appliedPriceSliderValue,
        setAppliedPriceSliderValue,
        teamsTableData,
        setTeamsTableData,
        teamsTableColumns,
        setTeamsTableColumns,
        numFixturesTeamsTable,
        setNumFixturesTeamsTable,
        highlightedPlayerIds,
        setHighlightedPlayerIds,
        playerSubstituteCandidateId,
        setPlayerSubstituteCandidateId,
        selectedDraftCaptainPositionId,
        setSelectedDraftCaptainPositionId,
        selectedDraftViceCaptainPositionId,
        setSelectedDraftViceCaptainPositionId,
        draftsList,
        setDraftsList,
        selectedDraftId,
        setSelectedDraftId,
        selectedDraft,
        setSelectedDraft,
        positionIdToTransfer,
        setPositionIdToTransfer,
        transferMode,
        setTransferMode,
        transferModeEmptyTeam,
        setTransferModeEmptyTeam,
        teamsSelectedForPlayersTable,
        setTeamsSelectedForPlayersTable,
        positionsSelectedForPlayersTable,
        setPositionsSelectedForPlayersTable,
        playersTableApplyTrigger,
        setPlayersTableApplyTrigger
      }}
    >
      {children}
    </SharedStateContext.Provider>
  )
}

export const useDraftsSharedState = () => useContext(SharedStateContext)
