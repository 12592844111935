import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function WorkInProgress () {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        // height: '100vh', // Full height of the viewport
        textAlign: 'center',
        width: '100%'
      }}
    >
      <Typography variant='h3' component='div' gutterBottom>
        Work in Progress
      </Typography>
      <Typography variant='h5' component='div'>
        Stay tuned!
      </Typography>
    </Box>
  )
}
