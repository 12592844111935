import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { getPlayers } from 'utils/draft'
import Box from '@mui/material/Box'
import PlayerBox from 'components/player_box/PlayerBox'
import { useEffect } from 'react'

export default function Keeper () {
  const { selectedDraft } = useDraftsSharedState()
  const { fplTeamId } = useAppSharedState()

  const keeper = getPlayers({
    draftTeam: selectedDraft,
    position: ['GKP'],
    inMainTeam: [true]
  })

  return (
    <>
      {fplTeamId && (
        <Box
          justifyContent='center'
          alignItems='center'
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {keeper.map(player => (
            <PlayerBox key={player.positionId} playerInfo={player} />
          ))}
        </Box>
      )}
    </>
  )
}
