import './PlayerBox.css'
import PlayerJersey from './PlayerJersey'
import PlayerName from './PlayerName'
import PlayerStat from './PlayerStat'
import Box from '@mui/material/Box'
import Fixtures from './Fixtures'
import Form from './Form'
import grey from '@mui/material/colors/grey'
import PlayerMenu from './PlayerMenu'
import Icon from '@mdi/react'
import { mdiAlphaCCircle } from '@mdi/js'
import { mdiAlphaVCircle } from '@mdi/js'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import yellow from '@mui/material/colors/yellow'

export default function PlayerBox ({ playerInfo }) {
  const {
    highlightedPlayerIds,
    setHighlightedPlayerIds,
    playerSubstituteCandidateId,
    setPlayerSubstituteCandidateId,
    setSelectedDraft,
    selectedDraftCaptainPositionId,
    selectedDraftViceCaptainPositionId,
    playerInfoRadioValue,
    positionIdToTransfer,
    playersTableData
  } = useDraftsSharedState()

  const handleSubstitution = event => {
    // event.stopPropagation() // Stop event propagation to document

    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }

      const firstPlayerIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerSubstituteCandidateId
      )
      const secondPlayerIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerInfo.position_id
      )

      if (firstPlayerIndex !== -1 && secondPlayerIndex !== -1) {
        // change inMainTeam to true if false and vice versa of both players

        const firstPlayerInMainTeam =
          newDraftTeam.players[firstPlayerIndex].in_main_team
        const secondPlayerInMainTeam =
          newDraftTeam.players[secondPlayerIndex].in_main_team
        newDraftTeam.players[firstPlayerIndex].in_main_team =
          secondPlayerInMainTeam

        newDraftTeam.players[secondPlayerIndex].in_main_team =
          firstPlayerInMainTeam

        newDraftTeam.players[firstPlayerIndex].position_id =
          playerInfo.position_id
        newDraftTeam.players[secondPlayerIndex].position_id =
          playerSubstituteCandidateId
        newDraftTeam.players.sort(
          (player1, player2) => player1.position_id - player2.position_id
        )
      }

      setPlayerSubstituteCandidateId(null) // Clear the candidate after substitution
      setHighlightedPlayerIds([])
      return newDraftTeam
    })
  }

  return (
    <Box
      sx={{
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        width: { xs: '68px', sm: '100px', md: '100px' },
        height: { xs: '98px', sm: '134px', md: '134px' }
      }}
    >
      <Box
        className={`PlayerBox ${
          highlightedPlayerIds.includes(playerInfo.position_id)
            ? 'highlight'
            : ''
        }`}
        alignItems='center'
        onClick={
          highlightedPlayerIds.includes(playerInfo.position_id)
            ? handleSubstitution
            : null
        }
        sx={{
          cursor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? 'pointer'
            : 'default',
          display: 'flex',
          flexDirection: 'column',
          mt: theme => {
            return {
              xs: theme.spacing(0.5),
              sm: theme.spacing(1),
              md: theme.spacing(1)
            }
          },
          mb: theme => {
            return {
              xs: theme.spacing(0.5),
              sm: theme.spacing(1),
              md: theme.spacing(1)
            }
          },
          ml: theme => theme.spacing(0.5),
          mr: theme => theme.spacing(0.5),
          border: `1.5px solid`,
          borderColor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? yellow
            : grey[700],
          backgroundColor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? grey[100]
            : playerInfo.position_id === positionIdToTransfer
            ? grey[500]
            : playerInfo.position_id === playerSubstituteCandidateId
            ? grey[500]
            : null,
          borderRadius: '0.5em'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center the jersey and menu horizontally
            alignItems: 'center',
            width: '100%',
            position: 'relative' // To allow absolute positioning inside this box
          }}
        >
          {playerInfo.id &&
            playerInfo.position_id === selectedDraftCaptainPositionId && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '0.05em',
                  top: '0.2em'
                }}
              >
                <Icon path={mdiAlphaCCircle} size={0.8} color={grey[900]} />
              </Box>
            )}
          {playerInfo.id &&
            playerInfo.position_id === selectedDraftViceCaptainPositionId && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '0.05em',
                  top: '0.2em'
                }}
              >
                <Icon path={mdiAlphaVCircle} size={0.8} color={grey[900]} />
              </Box>
            )}

          <PlayerJersey playerInfo={playerInfo} />
          {
            <Box
              sx={{
                position: 'absolute',
                right: '-0.4em',
                top: '-0.3em'
              }}
            >
              <PlayerMenu playerInfo={playerInfo} />
            </Box>
          }
        </Box>
        <PlayerName playerInfo={playerInfo} />

        {(playerInfoRadioValue === 'ep_this' ||
          playerInfoRadioValue === 'now_cost' ||
          playerInfoRadioValue === 'selected_by_percent') && (
          <PlayerStat valueToDisplay={playerInfo.stats[playerInfoRadioValue]} />
        )}

        {playerInfoRadioValue === 'position' && (
          <PlayerStat
            valueToDisplay={playerInfo.id !== null ? playerInfo.position : null}
          />
        )}

        {playerInfoRadioValue === 'team' && (
          <PlayerStat valueToDisplay={playerInfo.team} />
        )}

        {playerInfoRadioValue === 'form' && playerInfo.id && (
          <Form
            form={
              playersTableData.filter(
                player => player.player_id === playerInfo.id
              )[0].form_array
            }
          />
        )}

        {playerInfoRadioValue === 'fixtures' && playerInfo.id && (
          <Fixtures
            fixtures={
              playersTableData.filter(
                player => player.player_id === playerInfo.id
              )[0].fixtures
            }
          />
        )}

        {playerInfoRadioValue === 'xp' && playerInfo.id && (
          <PlayerStat
            valueToDisplay={
              playersTableData.filter(
                player => player.player_id === playerInfo.id
              )[0].ep_next
            }
          />
        )}
      </Box>
    </Box>
  )
}
