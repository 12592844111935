import './PlayerName.css'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'

export default function PlayerName ({ playerInfo }) {
  return (
    <>
      <Typography
        className='PlayerName__Name'
        display='block'
        variant='caption'
        align='center'
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%' },
          height: '1.5em',
          fontSize: { xs: '10px', sm: '10px', md: '12px' },
          alignContent: 'center',
          backgroundColor: playerInfo.name !== null && grey[900],
          color: playerInfo.name !== null ? 'white' : grey[900],
          fontWeight: playerInfo.name !== null ? 400 : 700,
          margin: '0.1em',
          borderRadius: '0.4em',
          textWrap: 'nowrap',
          contain: 'strict'
        }}
      >
        {playerInfo.name !== null ? playerInfo.name : playerInfo.position}
      </Typography>
    </>
  )
}
