import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { ROUTES } from 'utils/globalSettings'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CONFIG from 'config.json'
import axiosService from 'utils/axios'
import { eventWrapper } from '@testing-library/user-event/dist/utils'
import { useRef } from 'react'

function Copyright (props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit'>FPL Garage</Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function SignIn () {
  const [loading, setLoading] = useState(false) // Add loading state
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false) // State for Forgot Password loader
  const navigate = useNavigate()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const emailRef = useRef(null)

  const handleForgotPassword = event => {
    event.preventDefault()
    setForgotPasswordLoading(true) // Set Forgot Password loading state to true

    const email = emailRef.current.value // Get the email value from the ref
    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.forgotPassword}`, {
        email: email
      })
      .then(res => {
        setSnackbarMessage({
          message: 'Email Sent to reset your password!',
          severity: 'success'
        })
        setSnackbarOpen(true)
        setForgotPasswordLoading(false)
      })
      .catch(err => {
        setSnackbarMessage({
          message: 'Failed to send email to reset password!',
          severity: 'error'
        })
        setSnackbarOpen(true)
        setForgotPasswordLoading(false)
      })
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true) // Set loading state to true

    const formData = new FormData(event.currentTarget)

    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.login}`, formData)
      .then(res => {
        localStorage.setItem('auth', JSON.stringify(res.data))
        setLoading(false)
        navigate('/')
      })
      .catch(err => {
        setLoading(false)
        setSnackbarMessage({
          message: err.response.data.detail,
          severity: 'error'
        })
        setSnackbarOpen(true)
      })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            inputRef={emailRef} // Attach ref to email input
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          {/* <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          /> */}
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Signing In...' : 'Sign In'}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.main',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
          <Grid container>
            <Grid item xs>
              <Button
                variant='text'
                onClick={handleForgotPassword}
                sx={{ textTransform: 'none', padding: 0 }}
                disabled={forgotPasswordLoading} // Disable button during API call
              >
                Forgot password?
                {forgotPasswordLoading && (
                  <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                )}
              </Button>
            </Grid>
            <Grid item>
              <Link href='/register/' variant='body2'>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  )
}
