import './PlayerJersey.css'
import Box from '@mui/material/Box'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import Tooltip from '@mui/material/Tooltip'
import grey from '@mui/material/colors/grey'
import { Snackbar } from '@mui/material'
import { useState } from 'react'
import Alert from '@mui/material/Alert'
import { useDraftsSharedState } from 'state/DraftsSharedContext'

import CONFIG from 'config.json'

export default function PlayerJersey ({ playerInfo }) {
  const { highlightedPlayerIds } = useDraftsSharedState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const {
    setHighlightedPlayerIds,
    setPlayerSubstituteCandidateId,
    setPositionIdToTransfer,
    setTransferMode,
    setTransferModeEmptyTeam
  } = useDraftsSharedState()

  const handleAddPlayerClick = () => {
    setPositionIdToTransfer(playerInfo.position_id)
    setTransferMode(true)
    setTransferModeEmptyTeam(true)
    setHighlightedPlayerIds([])
    setPlayerSubstituteCandidateId(null)
    setSnackbarMessage({
      message: 'Click on add player icon from table',
      severity: 'success'
    })
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }
  return (
    <Box
      className='PlayerJersey'
      display='flex'
      justifyContent='center'
      alignContent='center'
      sx={{
        width: '2.5em',
        height: { xs: '2em', sm: '3em', md: '3em' }
      }}
    >
      {playerInfo.team !== null ? (
        <Box
          component='img'
          className='PlayerJersey__Img'
          src={`${
            process.env.PUBLIC_URL
          }/images/jerseys/${playerInfo.team.toLowerCase()}.png`}
          alt={playerInfo.team}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            position: 'relative'
          }}
        />
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            right: '0.5em'
          }}
        >
          <PersonAddAlt1Icon
            cursor='pointer'
            fontSize='large'
            onClick={
              highlightedPlayerIds.includes(playerInfo.positionId)
                ? null
                : handleAddPlayerClick
            }
            sx={{
              color: grey[800]
            }}
          />
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
