import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'

export default function PlayerCell ({ playerName, playerTeam, playerPosition }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // padding: '0.2em',
        borderBottom: `1px solid ${grey[300]}`
      }}
    >
      <Typography
        variant='body1'
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '10px', sm: '10px', md: '12px' },
          lineHeight: '1.2',
          textOverflow: 'ellipsis', // Truncate with ellipsis
          whiteSpace: 'nowrap' // Keep it in a single line
          // marginRight: '8px' // Small gap between name and team
        }}
      >
        {playerName} ({playerTeam})
      </Typography>

      {/* Second Row: Player Position */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          marginTop: '2px' // Reduce gap between rows
        }}
      >
        <Typography
          variant='caption'
          sx={{
            color: grey[500],
            fontSize: { xs: '10px', sm: '10px', md: '12px' },
            fontStyle: 'italic'
          }}
        >
          {playerPosition}
        </Typography>
      </Box>
    </Box>
  )
}
