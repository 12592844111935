import { useDraftsSharedState } from 'state/DraftsSharedContext'
import grey from '@mui/material/colors/grey'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { InputLabel } from '@mui/material'

export default function OpenDraftSelector () {
  const { draftsList, selectedDraftId, setSelectedDraftId } =
    useDraftsSharedState()

  const handleDraftChange = event => {
    setSelectedDraftId(event.target.value)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <FormControl
        variant='filled'
        size='small'
        sx={{
          backgroundColor: grey[1000],
          border: '1px solid grey',
          borderRadius: '0.2em',
          fontWeight: '400'
        }}
      >
        <InputLabel
          id='demo-simple-select-filled-label'
          size='small'
          shrink={true}
          sx={{ fontSize: { xs: '0.8em', sm: '1em', md: '1em' } }}
        >
          Open Draft
        </InputLabel>
        <Select
          size='small'
          labelId='demo-simple-select-filled-label'
          id='demo-simple-select-filled'
          label='Open Draft'
          value={selectedDraftId} // Set the current value of the select
          onChange={handleDraftChange} // Handle the change event
          sx={{
            fontSize: { xs: '0.8em', sm: '1em', md: '1em' },
            height: { xs: '40px', sm: '40px', md: '40px' }
          }}
        >
          {draftsList.map(draft => (
            <MenuItem key={draft.id} value={draft.id}>
              {draft.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
