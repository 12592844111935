import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import CircularProgress from '@mui/material/CircularProgress'
import axiosService from 'utils/axios'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { ROUTES } from 'utils/globalSettings'
import CONFIG from 'config.json'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

export default function CreateDraftButton () {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const [open, setOpen] = useState(false)
  const [draftType, setDraftType] = useState('last_gw')
  const [loading, setLoading] = useState(false)
  const { draftsList, setDraftsList, setSelectedDraftId } =
    useDraftsSharedState()
  const [draftName, setDraftName] = useState(
    'Draft #' + (draftsList.length + 1)
  )
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  useEffect(() => {
    setDraftName('Draft #' + (draftsList.length + 1))
  }, [draftsList])

  const handleClickOpen = () => {
    if (draftsList.length >= 3) {
      setSnackbarMessage({
        message: 'Cannot create more than 3 drafts!',
        severity: 'error'
      })
      setSnackbarOpen(true)
      return
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDraftTypeChange = event => {
    setDraftType(event.target.value)
  }

  const handleDraftNameChange = event => {
    setDraftName(event.target.value)
  }

  const getNewDraftsList = () => {
    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)
    })
  }

  const handleCreateDraft = () => {
    setLoading(true)

    axiosService
      .post(ROUTES.createDraft, {
        name: draftName,
        base: draftType
      })
      .then(response => {
        setLoading(false)
        setSelectedDraftId(response.data.id)
        getNewDraftsList()
        setSnackbarMessage({
          message: 'Draft created successfully!',
          severity: 'success'
        })
        setSnackbarOpen(true)
        handleClose()
      })
      .catch(error => {
        setLoading(false)
        setSnackbarMessage({
          message: 'Failed to create draft! Please try again.',
          severity: 'error'
        })
        setSnackbarOpen(true)
      })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          mr: '0.15em',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center'
        }}
      >
        <Button
          variant='contained'
          size='small'
          color='primary'
          onClick={handleClickOpen}
          sx={{
            textWrap: 'nowrap',
            fontSize: { xs: '0.6em', sm: '0.8em', md: '0.8em' },
            padding: '0.4em',
            height: { xs: '40px', sm: '40px', md: '40px' },
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            justifyItems: 'center'
          }}
        >
          Create Draft
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Draft</DialogTitle>
        <DialogContent>
          <DialogContentText>Provide a draft name.</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='draftName'
            label='Draft Name'
            type='text'
            fullWidth
            variant='outlined'
            value={draftName}
            onChange={handleDraftNameChange}
          />
          <FormControl component='fieldset' sx={{ mt: 3 }}>
            <FormLabel component='legend'>Create Draft From :</FormLabel>
            <RadioGroup
              aria-label='draft-type'
              name='draft-type'
              value={draftType}
              onChange={handleDraftTypeChange}
            >
              <FormControlLabel
                value='last_gw'
                control={<Radio />}
                label='Your Last Gameweek Team'
              />
              <FormControlLabel
                value='empty'
                control={<Radio />}
                label='Empty Team'
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleCreateDraft}
            color='primary'
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Draft'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </>
  )
}
