import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'

const fdrBgColorMapping = {
  1: green[900],
  2: green[500],
  3: grey[400],
  4: red[500],
  5: red[900]
}
const fdrTextColorMapping = {
  1: 'white',
  2: 'black',
  3: 'black',
  4: 'white',
  5: 'white'
}

export default function Fixtures ({ fixtures }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // border: '1px solid black',
        borderRadius: '0.4em',
        mt: '0.05em',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
      }}
    >
      {fixtures.map(fixture => (
        <Typography
          align='center'
          variant='caption'
          sx={{
            width: {
              xs: '3.8em',
              sm: '4em',
              md: '4em'
            }, // height: '1.8em',
            fontSize: { xs: '7px', sm: '10px', md: '10px' },
            fontWeight: 600,
            backgroundColor: fdrBgColorMapping[fixture.fdr],
            color: fdrTextColorMapping[fixture.fdr],
            border: '1px solid black',
            alignContent: 'center',
            borderRadius: '0.2em',
            // paddingLeft: '0.2em',
            // paddingRight: '0.2em',
            margin: {
              xs: '0.05em',
              sm: '0.05em',
              md: '0.05em'
            }
          }}
        >
          {fixture.team}
          {'-' + fixture.home_away}
        </Typography>
      ))}
    </Box>
  )
}
