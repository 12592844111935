import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute ({ children }) {
  const auth = JSON.parse(localStorage.getItem('auth'))

  const isAuthenticated =
    auth && auth.user && auth.user.is_verified && auth.user.email

  return isAuthenticated ? <>{children}</> : <Navigate to='/login/' />
}
