import './TeamsFixturesPanel.css'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import TeamsTable from './TeamsTable/TeamsTable'
import NumFixturesSelect from './NumFixturesSelect'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

export default function TeamFixturesPanel () {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // border: '1px solid grey',
        // mt: theme => theme.spacing(0.5),
        // ml: theme => theme.spacing(0.5),
        // mr: theme => theme.spacing(0.5),
        borderRadius: '0.5em',
        // overflowY: 'auto',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          // flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          // width: '100%',
          flexWrap: 'wrap'
        }}
      >
        <NumFixturesSelect />
      </Box>
      <TeamsTable />
    </Box>
  )
}
