import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { getPlayers } from 'utils/draft'
import Box from '@mui/material/Box'
import PlayerBox from 'components/player_box/PlayerBox'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'
import CONFIG from 'config.json'

export default function Subs () {
  const { selectedDraft, draftsList } = useDraftsSharedState()
  const { fplTeamId } = useAppSharedState()

  const subs = getPlayers({ draftTeam: selectedDraft, inMainTeam: [false] })

  return (
    <>
      {fplTeamId && draftsList.length > 0 && (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '600',
            padding: '0.1em',
            backgroundColor: green[300],
            color: grey[900],
            textAlign: 'center'
          }}
        >
          Bench
        </Typography>
      )}
      <Box
        className='TeamFieldPanel__Subs'
        justifyContent='space-evenly'
        alignItems='center'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: green[300],
          borderBottomLeftRadius: '0.5em',
          borderBottomRightRadius: '0.5em',
          borderTop: '2px solid grey',
          height: 'max-content',
          minWidth: { xs: '90vw', sm: '100%', md: '34em' },
          maxWidth: {
            xs: CONFIG['maxDraftFieldWidth'],
            sm: CONFIG['maxDraftFieldWidth'],
            md: CONFIG['maxDraftFieldWidth']
          }
        }}
      >
        {fplTeamId &&
          subs.map(player => (
            <PlayerBox key={player.positionId} playerInfo={player} />
          ))}
      </Box>
    </>
  )
}
