// DraftsSharedContext.js
import { createContext, useContext, useEffect, useState } from 'react'
import CONFIG from 'config.json'
import { ROUTES } from 'utils/globalSettings'
import axiosService from 'utils/axios'

const SharedStateContext = createContext()

export const AppSharedStateProvider = ({ children }) => {
  const [gameweek, setGameweek] = useState(CONFIG['gameweek'])
  const [fplTeamId, setFplTeamId] = useState(null)
  const [teamInfo, setTeamInfo] = useState({})
  const [gameweekInfo, setGameweekInfo] = useState({})
  const [managerSummary, setManagerSummary] = useState({})
  const [teamIdDialogOpen, setTeamIdDialogOpen] = useState(false)

  useEffect(() => {
    axiosService
      .get(ROUTES.userDetail)
      .then(response => {
        setFplTeamId(response.data.team_id)
      })
      .catch(error => {
        console.error(error)
      })
  }, [fplTeamId])

  //  get team info when team id changes
  useEffect(() => {
    axiosService
      .get(ROUTES.getTeamInfo)
      .then(response => {
        setTeamInfo(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [fplTeamId])

  //  get gameweek info when gameweek changes
  useEffect(() => {
    axiosService
      .get(ROUTES.getGameweekInfo)
      .then(response => {
        setGameweekInfo(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [fplTeamId])

  return (
    <SharedStateContext.Provider
      value={{
        gameweek,
        setGameweek,
        fplTeamId,
        setFplTeamId,
        managerSummary,
        setManagerSummary,
        teamInfo,
        setTeamInfo,
        gameweekInfo,
        setGameweekInfo,
        teamIdDialogOpen,
        setTeamIdDialogOpen
      }}
    >
      {children}
    </SharedStateContext.Provider>
  )
}

export const useAppSharedState = () => useContext(SharedStateContext)
