import { Box, Typography } from '@mui/material'

export default function Brand () {
  return (
    <Box
      sx={{
        display: {
          xs: 'flex',
          sm: 'flex'
        },
        alignContent: 'center',
        alignItems: 'center',
        mr: '0.5em'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mr: '0.5em' }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.webp`} // Replace with your logo path
          alt='FPL Garage Logo'
          style={{ width: 40, height: 40, borderRadius: '50%' }} // Adjust the size as needed
        />
      </Box>
      <Typography
        variant='h6'
        component='a'
        sx={{
          // flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: { xs: 700, sm: 700, md: 700 },
          // letterSpacing: '.1rem',
          color: 'inherit',
          textDecoration: 'none',
          display: { xs: 'block', sm: 'block' },
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
          textWrap: 'wrap'
        }}
      >
        FPLGarage
      </Typography>
    </Box>
  )
}
