import MaterialTable from '@material-table/core'
import axiosService from 'utils/axios'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import { useEffect, useState } from 'react'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import staticTableColumns from './table.json'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useRef } from 'react'
import CONFIG from 'config.json'
import { useAppSharedState } from 'state/AppSharedContext'
import PlayerCell from './PlayerCell'
import {
  checkIfPlayerExists,
  checkIfPositionAvailable,
  calculateNewInBankAfterAddingPlayer,
  calculateNewTeamValueAfterAddingPlayer,
  calculateNewTeamAvgOwnershipAfterAddingPlayer,
  calculateNewInBankAfterTransferringPlayer,
  calculateNewTeamValueAfterTransferringPlayer,
  calculateNewTeamAvgOwnershipAfterTransferringPlayer,
  getPlayers,
  calculateTransfersLeftTransfersCost
} from 'utils/draft'
import { ROUTES } from 'utils/globalSettings'
import { IconButton } from '@mui/material'

export default function PlayersTable () {
  const { fplTeamId, teamInfo, gameweekInfo } = useAppSharedState()

  const {
    playersTableData,
    setPlayersTableData,
    priceSliderValue,
    setSelectedDraft,
    positionIdToTransfer,
    setPositionIdToTransfer,
    transferMode,
    setTransferMode,
    transferModeEmptyTeam,
    setTransferModeEmptyTeam,
    teamsSelectedForPlayersTable,
    positionsSelectedForPlayersTable,
    playersTableApplyTrigger,
    setPlayersTableApplyTrigger
  } = useDraftsSharedState()

  const addPlayerAlertMessages = CONFIG['addPlayerAlertMessages']
  const stateRef = useRef()
  stateRef.fplTeamId = fplTeamId

  const [columns, setColumns] = useState(staticTableColumns)
  const [loading, setLoading] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })

  const processPlayersColumns = playersData => {
    const newColumns = JSON.parse(JSON.stringify(staticTableColumns)) // Deep clone
    newColumns[0].cellStyle = {
      backgroundColor: transferMode ? '#36454F' : 'black',
      position: 'sticky',
      left: 50,
      zIndex: 1,
      textWrap: 'nowrap'
    }
    newColumns[0].render = rowData => {
      return (
        <>
          <PlayerCell
            playerName={rowData.web_name}
            playerTeam={rowData.team_name}
            playerPosition={rowData.position}
          />
        </>
      )
    }

    setColumns(newColumns)
  }

  const getPlayersData = () => {
    const [minPrice, maxPrice] = priceSliderValue

    if (minPrice === undefined && maxPrice === undefined) {
      return
    }

    if (minPrice === null || maxPrice === null) {
      return
    }

    // const commaSeparatedStringParser = str =>
    //   str ? str.split(',').map(item => item.trim()) : []

    const queryPayload = {
      min_price: minPrice,
      max_price: maxPrice,
      teams: teamsSelectedForPlayersTable,
      positions: positionsSelectedForPlayersTable
    }
    axiosService
      .post(ROUTES.playersList, queryPayload)
      .then(response => {
        setPlayersTableData(response.data)
        processPlayersColumns(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (playersTableApplyTrigger) {
      setLoading(true)
      getPlayersData()
      setPlayersTableApplyTrigger(false)
    }
  }, [playersTableApplyTrigger])

  useEffect(() => {
    if (gameweekInfo) {
      getPlayersData()
    }
  }, [gameweekInfo])

  const handleAddPlayerTransferMode = (event, rowData) => {
    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }
      const playerToBeTransferred = newDraftTeam.players.find(
        player => player.position_id === positionIdToTransfer
      )

      // Check if fpl team id is synced
      if (stateRef.fplTeamId === null) {
        setSnackbarMessage(addPlayerAlertMessages['noTeamId'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)
        setPositionIdToTransfer(null)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if player id already exists in the team
      if (checkIfPlayerExists(newDraftTeam, rowData.player_id)) {
        setSnackbarMessage(addPlayerAlertMessages['playerExists'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam // Return previous state without changes
      }

      if (rowData.position !== playerToBeTransferred.position) {
        setSnackbarMessage({
          message: 'Add player with position ' + playerToBeTransferred.position,
          severity: 'error'
        })
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam
      }

      const newInBank = calculateNewInBankAfterTransferringPlayer(
        newDraftTeam.in_bank,
        playerToBeTransferred.stats.selling_price == null
          ? playerToBeTransferred.stats.now_cost
          : playerToBeTransferred.stats.selling_price,
        rowData.now_cost
      )

      const newTeamValue = calculateNewTeamValueAfterTransferringPlayer(
        newDraftTeam.team_value,
        playerToBeTransferred.stats.now_cost,
        rowData.now_cost
      )

      const newTeamAvgOwnership =
        calculateNewTeamAvgOwnershipAfterTransferringPlayer(
          newDraftTeam,
          playerToBeTransferred.stats.selected_by_percent,
          rowData.selected_by_percent
        )

      // Check if enough money in bank
      // if (newInBank < 0) {
      //   setSnackbarMessage(addPlayerAlertMessages['noFunds'])
      //   setSnackbarOpen(true)
      //   setTransferMode(false)
      //   setTransferModeEmptyTeam(false)

      //   setPositionIdToTransfer(null)
      //   return prevDraftTeam
      // }

      // Check if 3 players already from same team
      const playersFromSameTeam = getPlayers({
        draftTeam: newDraftTeam,
        teamFilter: [rowData.team_name],
        onlyAdded: true
      })

      if (playersFromSameTeam.length >= 3) {
        setSnackbarMessage(addPlayerAlertMessages['maxPlayersFromSameTeam'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam
      }
      const positionIndex = newDraftTeam.players.findIndex(
        player => player.position_id === positionIdToTransfer
      )

      // find player object from teaminfo.picks.players matching transferred player id
      const playerAlreadyInOriginalTeam = teamInfo.picks.players.find(
        player => player.id === rowData.player_id
      )

      if (!transferModeEmptyTeam && playerAlreadyInOriginalTeam) {
        newDraftTeam.players[positionIndex] = {
          id: playerAlreadyInOriginalTeam.id,
          name: playerAlreadyInOriginalTeam.name,
          team: playerAlreadyInOriginalTeam.team,
          position: playerAlreadyInOriginalTeam.position,
          stats: {
            now_cost: playerAlreadyInOriginalTeam.stats.now_cost,
            ep_this: playerAlreadyInOriginalTeam.stats.ep_this,
            form: playerAlreadyInOriginalTeam.stats.form,
            selected_by_percent:
              playerAlreadyInOriginalTeam.stats.selected_by_percent,
            purchase_price: playerAlreadyInOriginalTeam.stats.purchase_price,
            selling_price: playerAlreadyInOriginalTeam.stats.selling_price
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      } else {
        newDraftTeam.players[positionIndex] = {
          id: rowData.player_id,
          name: rowData.web_name,
          team: rowData.team_name,
          position: rowData.position,
          stats: {
            now_cost: rowData.now_cost,
            ep_this: rowData.ep_this,
            form: rowData.form,
            selected_by_percent: rowData.selected_by_percent,
            purchase_price: null,
            selling_price: null
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      }

      // Updating manager summary
      newDraftTeam.in_bank = newInBank
      newDraftTeam.team_value = newTeamValue
      newDraftTeam.avg_own = newTeamAvgOwnership

      const transfersLeftTransfersCost = calculateTransfersLeftTransfersCost(
        teamInfo,
        newDraftTeam
      )

      newDraftTeam.transfers_left = transfersLeftTransfersCost.transfersLeft
      newDraftTeam.transfers_cost = transfersLeftTransfersCost.transfersCost

      setSnackbarMessage(addPlayerAlertMessages['playerTransferred'])
      setSnackbarOpen(true)
      setTransferMode(false)
      setTransferModeEmptyTeam(false)
      setPositionIdToTransfer(null)

      return newDraftTeam
    })
  }

  const handleAddPlayer = (event, rowData) => {
    // const { name, team, position, stats } = rowData

    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }
      // const newManagerSummary = { ...stateRef.managerSummary }

      // Check if fpl team id is synced
      if (stateRef.fplTeamId === null) {
        setSnackbarMessage(addPlayerAlertMessages['noTeamId'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if player id already exists in the team
      if (checkIfPlayerExists(newDraftTeam, rowData.player_id)) {
        setSnackbarMessage(addPlayerAlertMessages['playerExists'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if position is available
      if (!checkIfPositionAvailable(newDraftTeam, rowData.position)) {
        setSnackbarMessage(addPlayerAlertMessages['noPositionAvailable'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      const newInBankAfterAddingPlayer = calculateNewInBankAfterAddingPlayer(
        newDraftTeam.in_bank,
        rowData.now_cost
      )
      const newTeamValue = calculateNewTeamValueAfterAddingPlayer(
        newDraftTeam.team_value,
        rowData.now_cost
      )
      const newTeamAvgOwnership = calculateNewTeamAvgOwnershipAfterAddingPlayer(
        newDraftTeam,
        rowData.selected_by_percent
      )

      // // Check if enough money in bank
      // if (newInBankAfterAddingPlayer < 0) {
      //   setSnackbarMessage(addPlayerAlertMessages['noFunds'])
      //   setSnackbarOpen(true)
      //   return prevDraftTeam
      // }

      // Check if 3 players already from same team
      const playersFromSameTeam = getPlayers({
        draftTeam: newDraftTeam,
        teamFilter: [rowData.team_name],
        onlyAdded: true
      })

      if (playersFromSameTeam.length >= 3) {
        setSnackbarMessage(addPlayerAlertMessages['maxPlayersFromSameTeam'])
        setSnackbarOpen(true)
        return prevDraftTeam
      }

      // Add new player
      const positionIndex = newDraftTeam.players.findIndex(
        player => player.position === rowData.position && player.id === null
      )

      // find player object from teaminfo.picks.players matching transferred player id
      const playerAlreadyInOriginalTeam = teamInfo.picks.players.find(
        player => player.id === rowData.player_id
      )

      if (playerAlreadyInOriginalTeam) {
        newDraftTeam.players[positionIndex] = {
          id: playerAlreadyInOriginalTeam.id,
          name: playerAlreadyInOriginalTeam.name,
          team: playerAlreadyInOriginalTeam.team,
          position: playerAlreadyInOriginalTeam.position,
          stats: {
            now_cost: playerAlreadyInOriginalTeam.stats.now_cost,
            ep_this: playerAlreadyInOriginalTeam.stats.ep_this,
            form: playerAlreadyInOriginalTeam.stats.form,
            selected_by_percent:
              playerAlreadyInOriginalTeam.stats.selected_by_percent,
            purchase_price: playerAlreadyInOriginalTeam.stats.purchase_price,
            selling_price: playerAlreadyInOriginalTeam.stats.selling_price
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      } else {
        newDraftTeam.players[positionIndex] = {
          id: rowData.player_id,
          name: rowData.web_name,
          team: rowData.team_name,
          position: rowData.position,
          stats: {
            now_cost: rowData.now_cost,
            ep_this: rowData.ep_this,
            form: rowData.form,
            selected_by_percent: rowData.selected_by_percent,
            purchase_price: null,
            selling_price: null
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      }

      // Updating manager summary
      newDraftTeam.in_bank = newInBankAfterAddingPlayer
      newDraftTeam.team_value = newTeamValue
      newDraftTeam.avg_own = newTeamAvgOwnership

      const transfersLeftTransfersCost = calculateTransfersLeftTransfersCost(
        teamInfo,
        newDraftTeam
      )

      newDraftTeam.transfers_left = transfersLeftTransfersCost.transfersLeft
      newDraftTeam.transfers_cost = transfersLeftTransfersCost.transfersCost

      return newDraftTeam
    })
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  return (
    <Box
      sx={{
        border: `0.5px solid ${grey[800]}`,
        borderRadius: '0.5em',
        mt: theme => theme.spacing(1),
        width: '100%'
      }}
    >
      <MaterialTable
        style={{
          borderRadius: '0.5em',
          width: '100%'
        }}
        title={null}
        columns={columns.map(x => ({ ...x }))}
        data={playersTableData}
        isLoading={loading}
        localization={{
          toolbar: {
            nRowsSelected: '{0} player(s) selected',
            searchPlaceholder: 'Search Player'
          },
          body: {
            emptyDataSourceMessage: 'No players found'
          },
          pagination: {
            labelRowsPerPage: null,
            labelRowsSelect: 'Players',
            labelDisplayedRows: ''
          },
          header: {
            actions: null
          }
        }}
        options={{
          // tableLayout: 'fixed',
          // fixedColumns: {
          //   left: 1,
          //   right: 0
          // },
          actionsCellStyle: {
            backgroundColor: transferMode ? '#36454F' : 'black',
            position: 'sticky',
            left: 0,
            zIndex: 1
          },
          paging: true,
          padding: 'dense',
          pageSizeOptions: [10, 25],
          showFirstLastPageButtons: false,
          paginationPosition: 'bottom',
          pageSize: 25,
          paginationType: 'stepped',
          filtering: true,
          rowStyle: {
            fontSize: '0.8em',
            backgroundColor: grey[900]
          },
          headerStyle: {
            marginBottom: '1em',
            fontSize: '0.8em',
            backgroundColor: 'black',
            position: 'sticky',
            top: 0,
            zIndex: 2
          },
          searchFieldAlignment: 'left',
          searchFieldStyle: {
            fontSize: '0.8em',
            width: '80%',
            marginLeft: '2em'
          },
          searchAutoFocus: false,
          selection: false,
          showSelectAllCheckbox: false,
          showEmptyDataSourceMessage: true,
          columnsButton: true,
          maxBodyHeight: '450px'
        }}
        actions={[
          {
            icon: () => (
              <IconButton>
                <PersonAddAlt1Icon
                  fontSize='small'
                  // sx={{ color: transferMode ? 'white' : grey[800] }}
                />
              </IconButton>
            ),
            tooltip: 'Add Player',
            onClick: (event, rowData) =>
              transferMode
                ? handleAddPlayerTransferMode(event, rowData)
                : handleAddPlayer(event, rowData)
          }
        ]}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
