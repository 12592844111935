import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDraftsSharedState } from 'state/DraftsSharedContext'

const SELECT_WIDTH = '12em'

export default function NumFixturesSelect () {
  const { numFixturesTeamsTable, setNumFixturesTeamsTable } =
    useDraftsSharedState()

  const handleChange = event => {
    setNumFixturesTeamsTable(event.target.value)
  }

  return (
    <div>
      <FormControl
        variant='outlined'
        size='small'
        sx={{ mb: theme => theme.spacing(1.4), width: SELECT_WIDTH }}
      >
        <InputLabel id='demo-simple-select-label'>
          Number of Fixtures
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={numFixturesTeamsTable}
          label='Number of Fixtures'
          onChange={handleChange}
        >
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
