import './TeamFieldPanel.css'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import { Typography } from '@mui/material'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { useEffect } from 'react'
import Keeper from './Keeper'
import Defenders from './Defenders'
import Midfielders from './Midfielders'
import Forwards from './Forwards'
import Subs from './Subs'
import FieldTopNavBar from './FieldTopNavBar'
import CONFIG from 'config.json'
import PlayerInfoSelectButton from './PlayerInfoSelectButton'
import Button from '@mui/material/Button'

export default function TeamFieldPanel () {
  const { fplTeamId, setTeamIdDialogOpen } = useAppSharedState()
  const { draftsList } = useDraftsSharedState()

  const handleSetTeamId = () => {
    setTeamIdDialogOpen(true) // Open the dialog for setting the FPL Team ID
  }
  // const { setPlayerSubstituteCandidateId, setHighlightedPlayerIds } =
  //   useDraftsSharedState()

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     const highlightedBoxes = document.querySelectorAll('.PlayerBox.highlight')
  //     let clickedInside = false

  //     highlightedBoxes.forEach(box => {
  //       if (box.contains(event.target)) {
  //         clickedInside = true
  //       }
  //     })

  //     if (!clickedInside) {
  //       setPlayerSubstituteCandidateId(null)
  //       setHighlightedPlayerIds([])
  //     }
  //   }

  //   document.addEventListener('click', handleClickOutside)

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside)
  //   }
  // }, [setPlayerSubstituteCandidateId, setHighlightedPlayerIds])

  return (
    <Box
      className='TeamFieldPanel'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: theme => theme.spacing(2),
        ml: theme => {
          return {
            xs: theme.spacing(2),
            sm: theme.spacing(2),
            md: theme.spacing(2)
          }
        },
        mr: theme => {
          return {
            xs: theme.spacing(2),
            sm: theme.spacing(2),
            md: theme.spacing(1)
          }
        },
        height: { xs: 'max-content', sm: 'max-content', md: 'max-content' },
        width: {
          xs: '100%', // Full width for smaller screens
          sm: '100%', // Full width for medium screens
          md: 'auto', // Auto width for larger screens
          lg: 'auto',
          xl: 'auto'
        }
        // maxWidth: {
        //   xs: CONFIG['maxDraftFieldWidth'],
        //   sm: CONFIG['maxDraftFieldWidth'],
        //   md: CONFIG['maxDraftFieldWidth']
        // }
      }}
    >
      <FieldTopNavBar />
      <Box
        className='TeamFieldPanel__Team'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          borderRadius: '0.5em'
        }}
      >
        <Box
          className='TeamFieldPanel__MainTeam'
          alignContent='center'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/field.jpeg)`,
            backgroundSize: {
              xs: '100% 100%',
              sm: '100% 100%',
              md: '100% 100%'
            },
            backgroundPosition: '0em -0.5em',
            borderTopLeftRadius: '0.5em',
            borderTopRightRadius: '0.5em',
            height: { xs: '420px', sm: '600px', md: '540px' },
            position: 'relative',
            minWidth: { xs: '90vw', sm: '90vw', md: '34em' },
            maxWidth: {
              xs: CONFIG['maxDraftFieldWidth'],
              sm: CONFIG['maxDraftFieldWidth'],
              md: CONFIG['maxDraftFieldWidth']
            },
            justifyContent: 'space-evenly'
          }}
        >
          <PlayerInfoSelectButton />
          {fplTeamId !== null && draftsList.length === 0 ? (
            <Typography
              variant='body1'
              align='center'
              sx={{
                fontSize: { xs: '14px', sm: '20px', md: '20px' },
                fontWeight: '800',
                position: 'absolute',
                top: '40%',
                width: '100%'
              }}
            >
              Create a draft to sync team and get started!
            </Typography>
          ) : null}
          {fplTeamId === null ? (
            <Typography
              variant='body1'
              align='center'
              sx={{
                fontWeight: '600'
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={handleSetTeamId} // Trigger the set team ID dialog
              >
                Set FPL Team ID
              </Button>
            </Typography>
          ) : null}

          <Keeper />
          <Defenders />
          <Midfielders />
          <Forwards />
        </Box>
        <Subs />
      </Box>
    </Box>
  )
}
