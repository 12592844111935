import {
  FDR_BG_COLOR_MAPPING,
  FDR_TEXT_COLOR_MAPPING
} from 'utils/globalSettings'
import Box from '@mui/material/Box'

export default function Fixture ({ gameweek, teamsTableRowData }) {
  const fixture = teamsTableRowData[`gw_${gameweek.gameweek_num}_fixture`]

  const fdr = teamsTableRowData[`gw_${gameweek.gameweek_num}_fixture_fdr`]
  const bgColor = FDR_BG_COLOR_MAPPING[parseInt(fdr)]
  const textColor = FDR_TEXT_COLOR_MAPPING[parseInt(fdr)]

  return (
    <>
      {fixture && (
        <Box
          style={{
            backgroundColor: bgColor,
            color: textColor,
            padding: '0.4em',
            borderRadius: '0.5em',
            textAlign: 'center',
            fontSize: { xs: '8px', sm: '16px', md: '16px' }
          }}
        >
          {fixture}
        </Box>
      )}
    </>
  )
}
