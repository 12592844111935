import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import yellow from '@mui/material/colors/yellow'
import grey from '@mui/material/colors/grey'
import CONFIG from 'config.json'

export default function Fixtures ({ form }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // border: '1px solid black',
        // borderRadius: '0.4em',
        // backgroundColor: grey[900],
        mt: '0.05em',
        width: '100%',
        // height: '1.2em',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
      }}
    >
      {form.map(form => (
        <Typography
          align='center'
          variant='caption'
          sx={{
            fontSize: { xs: '6.2px', sm: '8.4px', md: '8.4px' },
            fontWeight: 700,
            // backgroundColor: fdrBgColorMapping[fixture.fdr],
            backgroundColor:
              (form <= CONFIG.formRange.poor && red[700]) ||
              (form > CONFIG.formRange.poor &&
                form <= CONFIG.formRange.avg &&
                grey[600]) ||
              (form > CONFIG.formRange.avg && green[700]),
            alignContent: 'center',
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
            border: '1px solid black',
            borderRadius: '0.4em',
            width: {
              xs: '2.2em',
              sm: '2.4em',
              md: '2.4em'
            },
            margin: {
              xs: '0.01em',
              sm: '0.01em',
              md: '0.05em'
            }
          }}
        >
          {form}
        </Typography>
      ))}
    </Box>
  )
}
