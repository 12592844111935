import { useTheme } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import GroupsIcon from '@mui/icons-material/Groups'
import InsightsIcon from '@mui/icons-material/Insights'
import DescriptionIcon from '@mui/icons-material/Description'
import EditNoteIcon from '@mui/icons-material/EditNote'
import Box from '@mui/material/Box'
import { Link, useLocation } from 'react-router-dom'
import grey from '@mui/material/colors/grey'

const pages = {
  'My Team': { icon: GroupsIcon, route: '/my-team/' },
  'Drafts Planner': { icon: EditNoteIcon, route: '/drafts-planner/' }
  // 'AI Assistant': { icon: InsightsIcon, route: '/ai-assistant/' },
  // Templates: { icon: DescriptionIcon, route: '/templates/' }
}

export default function SideNavBar () {
  const theme = useTheme()
  const location = useLocation()

  return (
    <Box
      sx={{
        width: '240px', // Fixed width for the sidebar
        bgcolor: grey[900], // Sidebar background color
        display: { xs: 'none', sm: 'none', md: 'flex' }, // Hide on small screens
        flexDirection: 'column',
        pt: theme.spacing(16), // Padding from the top (adjust as needed)
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Optional shadow
        overflowY: 'auto', // Enable scrolling if content overflows
        position: 'sticky', // Make sidebar fixed
        top: 0, // Align it to the top
        left: 0, // Align it to the left
        bottom: 0 // Stretch it to the bottom
        // zIndex: 1200 // Ensure it's above other elements
      }}
    >
      <List>
        {Object.entries(pages).map(([text, { icon: Icon, route }]) => {
          const isActive = location.pathname === route
          return (
            <ListItem
              key={text}
              component={Link}
              to={route}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                // Remove hover styles from ListItem
                // Manage active state only
                bgcolor: isActive ? grey[800] : 'transparent',
                '&:hover': {
                  backgroundColor: isActive ? '#16a085' : '#34495e' // Customize hover based on active state
                }
              }}
            >
              <ListItemButton
                disableRipple
                sx={{
                  padding: '12px 16px',
                  color: isActive ? '#ffffff' : '#ecf0f1',
                  '&:hover': {
                    backgroundColor: isActive ? 'transparent' : 'transparent' // Disable hover background change
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: isActive ? '#ffffff' : '#ecf0f1',
                    minWidth: '40px'
                  }}
                >
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{
                    fontSize: '16px',
                    fontWeight: isActive ? 600 : 500
                  }}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
      <Divider sx={{ bgcolor: '#34495e' }} />
      {/* Optional: Additional items can be added here */}
    </Box>
  )
}
