// Drafts.js
import './Drafts.css'
import Box from '@mui/material/Box'
import TeamSidePanel from 'components/team_side_panel/TeamSidePanel'
import TeamFieldPanel from 'components/team_field_panel/TeamFieldPanel'
import PlayersTeamsPanel from 'components/players_teams_panel/PlayersTeamsPanel'
import { DraftsSharedStateProvider } from '../../state/DraftsSharedContext' // Updated import

export default function Drafts () {
  return (
    <DraftsSharedStateProvider>
      <Box
        className='Drafts'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' },
          justifyItems: 'center',
          marginLeft: { xs: 0, sm: 0, md: 0 },
          overflowX: 'auto'
          // alignContent: 'space-evenly',
        }}
      >
        {/* <TeamSidePanel /> */}
        <TeamFieldPanel />
        <PlayersTeamsPanel />
      </Box>
    </DraftsSharedStateProvider>
  )
}
