import Card from '@mui/material/Card'
import { Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import Box from '@mui/material/Box'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import CONFIG from 'config.json'

export default function TeamBasicInfoCard () {
  const { selectedDraft } = useDraftsSharedState()
  const managerSummaryColumns = CONFIG['managerSummaryColumns']

  return (
    <Card
      variant='outlined'
      className='TeamSidePanel__TeamStats'
      sx={{
        backgroundColor: grey[900],
        mt: theme => theme.spacing(0.5),
        mb: theme => theme.spacing(0.5)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          justifyItems: 'space-evenly',
          paddingLeft: theme => theme.spacing(1),
          // paddingRight: theme => theme.spacing(1)
          // paddingTop: theme => theme.spacing(1)
        }}
      >
        {managerSummaryColumns.map((column, index) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: theme => theme.spacing(1),
              justifyContent: 'center',
              textAlign: 'center',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                mr: theme => theme.spacing(1),
                fontWeight: '600',
                fontSize: { xs: '0.7em', sm: '0.8em', md: '0.8em' }
              }}
            >
              {column.label}
            </Typography>
            <Typography
              variant='body2'
              sx={{ fontSize: { xs: '0.7em', sm: '0.8em', md: '0.8em' } }}
            >
              {selectedDraft[column.field] !== undefined
                ? selectedDraft[column.field]
                : column.default}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  )
}
