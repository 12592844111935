import Box from '@mui/material/Box'
import TeamSelector from './TeamSelector'
import PositionsSelector from './PositionsSelector'
import PreBuiltFiltersSelect from './PreBuiltFiltersSelect'
import PriceMinMaxInput from './PriceMinMaxInput'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'

export default function PlayersFilterSearchBox () {
  const { gameweekInfo } = useAppSharedState()
  const {
    setPriceSliderValue,
    setPlayersTableApplyTrigger,
    setTeamsSelectedForPlayersTable,
    setPositionsSelectedForPlayersTable
  } = useDraftsSharedState()

  const handleApply = () => {
    setPlayersTableApplyTrigger(true)
  }

  const handleReset = () => {
    setPriceSliderValue([
      gameweekInfo.min_player_price,
      gameweekInfo.max_player_price
    ])
    setTeamsSelectedForPlayersTable([])
    setPositionsSelectedForPlayersTable([])
    setPlayersTableApplyTrigger(true)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          // flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          // width: '100%',
          flexWrap: 'wrap'
        }}
      >
        {/* <PreBuiltFiltersSelect /> */}
        <TeamSelector />
        <PositionsSelector />
        <PriceMinMaxInput />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonGroup
          variant='contained'
          size='small'
          aria-label='Basic button group'
          sx={{ margin: theme => theme.spacing(0.5), height: '2.5em' }}
        >
          <Button
            variant='contained'
            onClick={handleApply}
            sx={{ fontSize: { xs: '12px', sm: '16px', md: '16px' } }}
          >
            Apply
          </Button>
          <Button
            variant='outlined'
            onClick={handleReset}
            sx={{ fontSize: { xs: '12px', sm: '16px', md: '16px' } }}
          >
            Reset
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}
