import { useAppSharedState } from 'state/AppSharedContext'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useTheme } from '@mui/material/styles'
import OpenDraftSelector from './OpenDraftSelector'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'
import SyncIcon from '@mui/icons-material/Sync'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import axiosService from 'utils/axios'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { ROUTES } from 'utils/globalSettings'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CONFIG from 'config.json'
import CreateDraftButton from 'components/team_side_panel/CreateDraftButton'
import TeamBasicInfoCard from 'components/team_side_panel/TeamBasicInfoCard'

export default function FieldTopNavBar () {
  const theme = useTheme()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false)
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const { gameweekInfo } = useAppSharedState()
  const { selectedDraftId, selectedDraft, setDraftsList } =
    useDraftsSharedState()

  const handleSave = () => {
    axiosService
      .put(ROUTES.saveDraft + selectedDraftId + '/', selectedDraft)
      .then(res => {
        setSnackbarMessage({
          message: 'Draft saved!',
          severity: 'success'
        })
        setSnackbarOpen(true)
      })
  }

  const confirmDiscardAllChanges = () => {
    setIsDiscardDialogOpen(false)
    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)
      setSnackbarMessage({
        message: 'Changes discarded!',
        severity: 'success'
      })
      setSnackbarOpen(true)
    })
  }

  const confirmSync = () => {
    setIsSyncDialogOpen(false)
    axiosService.post(ROUTES.syncDraft + selectedDraftId + '/').then(res => {
      axiosService.get(ROUTES.getDraftsList).then(response => {
        setDraftsList(response.data)

        setSnackbarMessage({
          message: 'Draft reset to last GW team!',
          severity: 'success'
        })
      })

      setSnackbarOpen(true)
    })
  }

  const handleSync = () => {
    setIsSyncDialogOpen(true)
  }

  const handleDiscardAllChanges = () => {
    setIsDiscardDialogOpen(true)
  }

  const handleDeleteDraft = () => {
    // Open the confirmation dialog
    setIsDeleteDialogOpen(true)
  }

  const confirmDeleteDraft = () => {
    // Close the dialog
    setIsDeleteDialogOpen(false)
    // Perform the delete operation
    axiosService
      .delete(ROUTES.deleteDraft + selectedDraftId + '/')
      .then(res => {
        axiosService.get(ROUTES.getDraftsList).then(response => {
          setDraftsList(response.data)

          setSnackbarMessage({
            message: 'Draft deleted!',
            severity: 'success'
          })
          setSnackbarOpen(true)
        })
      })
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false)
  }

  const handleDiscardDialogClose = () => {
    setIsDiscardDialogOpen(false)
  }

  const handleSyncDialogClose = () => {
    setIsSyncDialogOpen(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography
        display='block'
        variant='body1'
        align='center'
        sx={{
          backgroundColor: grey[1000],
          // mb: '0.2em',
          border: '1px solid grey',
          borderRadius: '0.2em',
          fontWeight: '400',
          width: '100%',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        {gameweekInfo.name}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: '0.2em',
          // mb: '0.2em',
          alignItems: 'stretch'
        }}
      >
        {/* <Tooltip title='Create New Draft' placement='top'>
          <IconButton
            onClick={selectedDraftId ? handleSync : null}
            variant='contained'
            sx={{
              border: '1px solid grey',
              borderRadius: '0.2em',
              // ml: '0.15em',
              mr: '0.05em',
              minHeight: '100%',
              backgroundColor: theme.palette.secondary.main
            }}
          >
            <AddIcon fontSize='normal'/>
          </IconButton>
        </Tooltip> */}

        <CreateDraftButton />

        <OpenDraftSelector />

        <Tooltip title='Sync Draft' placement='top'>
          <IconButton
            size='small'
            onClick={selectedDraftId ? handleSync : null}
            sx={{
              border: '1px solid grey',
              borderRadius: '0.2em',
              ml: '0.15em',
              mr: '0.05em',
              minHeight: '100%'
            }}
          >
            <SyncIcon size='small' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Save Draft' placement='top'>
          <IconButton
            size='small'
            onClick={selectedDraftId ? handleSave : null}
            sx={{
              border: '1px solid grey',
              borderRadius: '0.2em',
              ml: '0.1em',
              mr: '0.05em',
              minHeight: '100%'
            }}
          >
            <SaveIcon size='small' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Discard All Changes' placement='top'>
          <IconButton
            size='small'
            onClick={selectedDraftId ? handleDiscardAllChanges : null}
            sx={{
              border: '1px solid grey',
              borderRadius: '0.2em',
              ml: '0.05em',
              mr: '0.05em',
              minHeight: '100%'
            }}
          >
            <UndoIcon size='small' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Delete Draft' placement='top'>
          <IconButton
            size='small'
            onClick={selectedDraftId ? handleDeleteDraft : null}
            sx={{
              border: '1px solid grey',
              borderRadius: '0.2em',
              ml: '0.05em',
              mr: '0.05em',
              minHeight: '100%'
            }}
          >
            <DeleteIcon size='small' />
          </IconButton>
        </Tooltip>
      </Box>
      <TeamBasicInfoCard />

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Are you sure you want to delete this draft?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={confirmDeleteDraft}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDiscardDialogOpen} onClose={handleDiscardDialogClose}>
        <DialogTitle>
          Are you sure you want to discard all draft changes?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDiscardDialogClose}>Cancel</Button>
          <Button onClick={confirmDiscardAllChanges}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isSyncDialogOpen} onClose={handleSyncDialogClose}>
        <DialogTitle>
          Are you sure you want to reset this draft to your last gameweek team?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleSyncDialogClose}>Cancel</Button>
          <Button onClick={confirmSync}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
