import MaterialTable from '@material-table/core'
import axiosService from 'utils/axios'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import { useEffect, useState } from 'react'
import staticTableColumns from './table.json'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import Fixture from './Fixture'
import { ROUTES } from 'utils/globalSettings'

export default function TeamsTable () {
  const {
    teamsTableData,
    setTeamsTableData,
    teamsTableColumns,
    setTeamsTableColumns,
    numFixturesTeamsTable
  } = useDraftsSharedState()
  const [loading, setLoading] = useState(true)

  const processTeamsData = teamsData => {
    const { gameweeks, teams } = teamsData

    // Generate new columns for each gameweek
    const gameweekColumns = gameweeks.map(gw => ({
      title: gw.gameweek_name,
      field: `gw_${gw.gameweek_num}_fixture`,
      searchable: false,
      filtering: false,
      sorting: false,
      cellStyle: { whiteSpace: 'nowrap' },
      render: rowData => {
        return (
          <>
            <Fixture gameweek={gw} teamsTableRowData={rowData} />
          </>
        )
      }
    }))

    // Process teams data to include fixtures for each gameweek
    const processedTeamsData = teams.map(team => {
      const teamData = { ...team }
      team.fixtures.forEach(fixture => {
        teamData[
          `gw_${fixture.gameweek_num}_fixture`
        ] = `${fixture.team} (${fixture['home_away']})`

        teamData[`gw_${fixture.gameweek_num}_fixture_fdr`] = fixture.fdr
      })
      return teamData
    })

    const staticTableColumnsCopy = staticTableColumns.map(column => ({
      ...column
    }))
    var newColumns = []
    newColumns = [
      staticTableColumnsCopy[0], // First static column
      ...gameweekColumns, // Dynamic gameweek columns
      ...staticTableColumnsCopy.slice(1) // Remaining static columns
    ]
    setTeamsTableColumns(newColumns)
    setTeamsTableData(processedTeamsData)
  }

  useEffect(() => {
    const queryParams = `?num_fixtures=${numFixturesTeamsTable}`

    // Fetch data from API
    axiosService
      .get(ROUTES.teamsList + queryParams)
      .then(response => {
        processTeamsData(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }, [numFixturesTeamsTable])

  return (
    <Box
      sx={{
        border: `0.5px solid ${grey[800]}`,
        borderRadius: '0.5em',
        // mt: theme => theme.spacing(1),
        width: '100%'
      }}
    >
      <MaterialTable
        style={{
          borderRadius: '0.5em',
          width: '100%'
        }}
        title={null}
        columns={teamsTableColumns}
        data={teamsTableData}
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: 'No teams found'
          },
          header: {
            actions: null
          }
        }}
        options={{
          padding: 'dense',
          paging: false,
          rowStyle: { fontSize: '0.8em', backgroundColor: grey[900] },
          headerStyle: {
            fontSize: '0.8em',
            backgroundColor: 'black',
            position: 'sticky',
            top: 0,
            zIndex: 2
          },
          search: false,
          selection: false,
          showSelectAllCheckbox: false,
          showEmptyDataSourceMessage: true,
          columnsButton: true,
          maxBodyHeight: '600px'
        }}
      />
    </Box>
  )
}
