import './PlayerStat.css'
import { Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'

export default function PlayerStat ({ valueToDisplay }) {
  return (
    <>
      <Typography
        align='center'
        variant='caption'
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%' },
          height: '1.5em',
          fontSize: { xs: '10px', sm: '10px', md: '12px' },
          alignContent: 'center',
          backgroundColor:
            valueToDisplay !== undefined &&
            valueToDisplay !== null &&
            grey[900],
          color: 'white',
          margin: '0.05em',
          borderRadius: '0.4em'
        }}
      >
        {valueToDisplay}
      </Typography>
    </>
  )
}
