import './PlayersPanel.css'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PlayersFilterSearchBox from './players_filter_search_box/PlayersFilterSearchBox'
import PlayersTable from './PlayersTable'

export default function PlayersPanel () {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // border: '1px solid grey',
        // mt: theme => theme.spacing(0.5),
        // ml: theme => theme.spacing(0.5),
        // mr: theme => theme.spacing(0.5),
        borderRadius: '0.5em',
        alignItems: 'center',
        width: '100%',
        // flexGrow: 1
      }}
    >
      <PlayersFilterSearchBox />
      <PlayersTable />
    </Box>
  )
}
