import React from 'react'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

export default function AfterConfirmation () {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/login/') // Adjust this route to your login page route
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography component='h1' variant='h5'>
          Thank You!
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Your email has been successfully verified. You can now log in to your
          account.
        </Typography>
        <Button variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Container>
  )
}
