import React, { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import { ROUTES } from 'utils/globalSettings'
import CONFIG from 'config.json'

export default function SetNewPassword () {
  const [loading, setLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const [searchParams] = useSearchParams() // Use useSearchParams to access query parameters
  const uidb64 = searchParams.get('uidb64') // Get uidb64 from query parameters
  const token = searchParams.get('token') // Get token from query parameters
  const navigate = useNavigate()

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.currentTarget)

    const newPassword = formData.get('newPassword')
    const confirmPassword = formData.get('confirmPassword')

    if (newPassword !== confirmPassword) {
      setSnackbarMessage({
        message: 'Passwords do not match!',
        severity: 'error'
      })
      setSnackbarOpen(true)
      setLoading(false)
      return
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/${ROUTES.resetPassword}`, {
        uidb64,
        token,
        password: newPassword
      })
      .then(res => {
        setSnackbarMessage({
          message: 'Password has been reset successfully!',
          severity: 'success'
        })
        setSnackbarOpen(true)
        setLoading(false)
        navigate('/login') // Redirect to login page after successful password reset
      })
      .catch(err => {
        setSnackbarMessage({
          message: 'Failed to reset password!',
          severity: 'error'
        })
        setSnackbarOpen(true)
        setLoading(false)
      })
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Set New Password
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            name='newPassword'
            label='New Password'
            type='password'
            id='newPassword'
            autoComplete='new-password'
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Confirm Password'
            type='password'
            id='confirmPassword'
            autoComplete='new-password'
          />
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Resetting Password...' : 'Set Password'}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.main',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
