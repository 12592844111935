import React, { useEffect, useState } from 'react'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import FormControl from '@mui/material/FormControl'
import grey from '@mui/material/colors/grey'

export default function PositionsSelector () {
  const {
    setPositionsSelectedForPlayersTable,
    positionsSelectedForPlayersTable,
    transferMode,
    positionIdToTransfer,
    selectedDraft,
    setPlayersTableApplyTrigger
  } = useDraftsSharedState()

  const positions = ['GKP', 'DEF', 'MID', 'FWD']
  const [selectedPositions, setSelectedPositions] = useState([])

  const handlePositionSelectionChange = event => {
    const value = event.target.value

    // If no teams are selected, it will mean all teams are included
    if (value.length === 0) {
      setPositionsSelectedForPlayersTable([]) // Show all players
    } else {
      setPositionsSelectedForPlayersTable(value) // Apply filter for selected teams
    }

    setSelectedPositions(value) // Update the selected teams in the state
  }

  useEffect(() => {
    // Update the selected teams in the state
    setSelectedPositions(positionsSelectedForPlayersTable)
  }, [positionsSelectedForPlayersTable])

  useEffect(() => {
    if (transferMode) {
      const playerToBeTransferred = selectedDraft.players.find(
        player => player.position_id === positionIdToTransfer
      )
      setSelectedPositions([playerToBeTransferred.position])
      setPositionsSelectedForPlayersTable([playerToBeTransferred.position])
    } else {
      setSelectedPositions([])
      setPositionsSelectedForPlayersTable([])
    }
    setPlayersTableApplyTrigger(true)
  }, [transferMode])

  return (
    <FormControl
      size='small'
      variant='filled'
      sx={{
        margin: theme => theme.spacing(0.5),
        width: { xs: '100px', sm: '140px', md: '140px' },
        backgroundColor: grey[900],
        border: '1px solid grey',
        borderRadius: '0.2em',
        fontWeight: '400'
      }}
    >
      <InputLabel size='small' shrink={true}>
        Position
      </InputLabel>
      <Select
        size='small'
        labelId='demo-multiple-checkbox-label'
        multiple
        value={selectedPositions}
        onChange={handlePositionSelectionChange}
        renderValue={selected =>
          selected.length === 0 ? '' : selected.join(', ')
        } // Show "All Teams" if none selected
        sx={{
          width: '100%',
          fontSize: { xs: '10px', sm: '14px', md: '16px' },
          backgroundColor: grey[800],
          color: 'white'
        }}
      >
        {/* Render the list of teams */}
        {positions.map(team => (
          <MenuItem
            key={team}
            value={team}
            sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
          >
            <Checkbox size='small' checked={selectedPositions.includes(team)} />
            <ListItemText
              primary={team}
              sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
