import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'

import { getPlayers } from 'utils/draft'
import Box from '@mui/material/Box'
import PlayerBox from 'components/player_box/PlayerBox'

export default function Defenders () {
  const { selectedDraft } = useDraftsSharedState()
  const { fplTeamId } = useAppSharedState()

  const defenders = getPlayers({
    draftTeam: selectedDraft,
    position: ['DEF'],
    inMainTeam: [true]
  })

  return (
    <>
      {fplTeamId && (
        <Box
          justifyContent='space-evenly'
          alignItems='center'
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {defenders.map(player => (
            <PlayerBox key={player.positionId} playerInfo={player} />
          ))}
        </Box>
      )}
    </>
  )
}
