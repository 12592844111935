import React, { useState } from 'react'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import { ROUTES } from 'utils/globalSettings'

export default function EmailConfirmation () {
  const [isResending, setIsResending] = useState(false)
  const [resendSuccess, setResendSuccess] = useState(false)

  const handleResendEmail = () => {
    setIsResending(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.resendEmail}`, {
        email: JSON.parse(localStorage.getItem('auth')).user.email
      })
      .then(res => {
        setIsResending(false)
        setResendSuccess(true)
      })
      .catch(err => {
        console.log(err)
        setIsResending(false)
        setResendSuccess(false)
      })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography component='h1' variant='h5'>
          Verify Your Email
        </Typography>
        <Typography sx={{ mt: 2 }}>
          A verification email has been sent to your email address. Please check
          your inbox and click on the verification link to complete your
          registration.
        </Typography>
        <Typography
          sx={{ mt: 2, fontStyle: 'italic', color: 'text.secondary' }}
        >
          Didn’t receive the email? It may take a few minutes to arrive. Please
          also check your spam or junk folder.
        </Typography>
        <Button
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
          onClick={handleResendEmail}
          disabled={isResending}
        >
          {isResending ? <CircularProgress size={24} /> : 'Resend Email'}
        </Button>
        {resendSuccess && (
          <Typography sx={{ mt: 2, color: 'green' }}>
            A new verification email has been sent. Please check your inbox.
          </Typography>
        )}
        <Link href='/login' variant='body2' sx={{ mt: 4 }}>
          Go to Login
        </Link>
      </Box>
    </Container>
  )
}
