import { green, grey, red } from '@mui/material/colors'

// Define color mapping for FDR
const FDR_BG_COLOR_MAPPING = {
  1: green[900],
  2: green[500],
  3: grey[400],
  4: red[500],
  5: red[900]
}

const FDR_TEXT_COLOR_MAPPING = {
  1: 'white',
  2: 'black',
  3: 'black',
  4: 'white',
  5: 'white'
}

const ROUTES = {
  refreshToken: 'refresh-token/',
  register: 'user/register/',
  login: 'user/login/',
  logout: 'user/logout/',
  resendEmail: 'user/resend-verification-email/',
  setTeamId: 'user/set-team-id/',
  getGameweekInfo: 'user/get-gw-info/',
  getTeamInfo: 'user/get-team-info/',
  userDetail: 'user/detail/',
  forgotPassword: 'user/request-password-reset-email/',
  resetPassword: 'user/password-reset/',
  playersList: 'players/list/',
  teamsList: 'teams/list/',
  createDraft: 'draft/create/',
  getDraftsList: 'draft/list/',
  saveDraft: 'draft/save/',
  deleteDraft: 'draft/delete/',
  syncDraft: 'draft/sync/'
}

export { FDR_BG_COLOR_MAPPING, FDR_TEXT_COLOR_MAPPING, ROUTES }
